import React, {useState} from 'react';
import { graphql, useStaticQuery } from 'gatsby';
import InnerPagesUpperBanner from '../InnerPagesUpperBanner';
import Img from "gatsby-image";
import {
  DEVELOPMENT_ADVANTAGES_CARDS,
  DEVELOPMENT_ADVANTAGES_HEADING,
  DEVELOPMENT_AGILE_HEADING,
  DEVELOPMENT_AGILE_TEXT,
  DEVELOPMENT_DEDICATED_TEAM_CARDS,
  DEVELOPMENT_DEDICATED_TEAM_HEADING,
  DEVELOPMENT_DEDICATED_TEAM_TEXT,
  DEVELOPMENT_EXPERTISE_CARDS,
  DEVELOPMENT_EXPERTISE_HEADING,
  DEVELOPMENT_EXPERTISE_LEFT_PARAGRAPH,
  DEVELOPMENT_EXPERTISE_RIGHT_PARAGRAPH,
  DEVELOPMENT_INSIGHTS_HEADING,
  DEVELOPMENT_QA_HEADING,
  DEVELOPMENT_QA_TEXT,
  DEVELOPMENT_SERVICES_CARDS,
  DEVELOPMENT_SERVICES_HEADING,
  DEVELOPMENT_SERVICES_TEXT,
  SERVICE_DEVELOPMENT_HEADING,
  SERVICE_DEVELOPMENT_SUBHEADING
} from '../../constants/services-pages';
import Typography from '@material-ui/core/Typography';
import styles from './CustomSoftwareDevelopmentPage.module.scss';
import Feed from '../Feed';
import HorizontalCard from '../HorizontalCard';
import LeaveMessageBanner from '../LeaveMessageBanner';
import Button from "@material-ui/core/Button";
import KeyboardArrowDown from "@material-ui/icons/KeyboardArrowDown";
import KeyboardArrowUp from "@material-ui/icons/KeyboardArrowUp";

const CustomSoftwareDevelopmentPage = () => {
  const [expanded, setExpanded] = useState(false);
  const { bg, icons, mobileIcons, agile, advantagesIcons, insightsPosts, qa } = useStaticQuery(graphql`
    query {
      bg: allFile(
        filter: {
          sourceInstanceName: { eq: "services-development" }
          name: { eq: "bg" }
        }
      ) {
        nodes {
          name
          publicURL
        }
      }
      agile: allFile(
        filter: {
          sourceInstanceName: { eq: "services-development" }
          name: { eq: "agile" }
        }
      ) {
        nodes {
          name
          publicURL
          childImageSharp {
            fluid(maxWidth:862, quality: 100) {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
      qa: allFile(
        filter: {
          sourceInstanceName: { eq: "services-development" }
          name: { eq: "qa" }
          extension: { eq: "jpg" }
        }
      ) {
        nodes {
          name
          publicURL
          childImageSharp {
            fluid(maxWidth:590, quality: 100) {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
      icons: allFile(
        filter: {
          sourceInstanceName: { eq: "services-development" }
          relativeDirectory: { eq: "big-screen" }
        }
      ) {
        nodes {
          name
          publicURL
        }
      }
      mobileIcons: allFile(
        filter: {
          sourceInstanceName: { eq: "services-development" }
          relativeDirectory: { eq: "mobile" }
        }
      ) {
        nodes {
          name
          publicURL
        }
      }
      advantagesIcons: allFile(
        filter: {
          sourceInstanceName: { eq: "services-development" }
          relativeDirectory: { eq: "advantages-imgs" }
        }
      ) {
        nodes {
          name
          publicURL
        }
      }
      insightsPosts: allMarkdownRemark(
        filter: {
          fields: {
            slug: {
              in: ["/posts/google-cloud-migration-strategies-and-opportunities-beyond-usual-cloud-efficiencies", "/posts/10-reasons-why-it-outsourcing-in-ukraine-is-a-perfect-choice-for-global-businesses", "/posts/building-a-modern-digital-enterprise-with-google-cloud-data-fusion"]
            }
          }
          frontmatter: {
            template: {
              eq: "post"
            }
            draft: {
              ne: true
            }
          }
        }
      ) {
        nodes {
          fields {
            slug
            categorySlug
            tagSlugs
          }
          frontmatter {
            title
            date
            category
            description
            socialImage
            tags
          }
        }
      }
    }
  `);
  const advantagesMap = advantagesIcons.nodes.reduce((acc, el) => ({...acc, [el.name]: el.publicURL}),{});
  const postsEdges = insightsPosts.nodes.map(node => ({node}));
  return (
    <div className={styles['services-development']}>
      <InnerPagesUpperBanner
        bg={bg.nodes[0].publicURL}
        className={styles['dev-banner']}
        description={[SERVICE_DEVELOPMENT_SUBHEADING]}
        descriptionClassName={styles['dev-banner-description']}
        text={[SERVICE_DEVELOPMENT_HEADING]}
        textClassName={styles['dev-banner-text']}
      />

      <div className={styles['expertise-block']}>
        <div>
        <Typography
          variant={'h2'}
          className={styles['expertise-block__heading']}
          align="center" color="secondary"
        >
          {DEVELOPMENT_EXPERTISE_HEADING}
        </Typography>

        <div className={`${styles['expertise-block__text']} ${expanded ? styles['expanded'] : ''}`}>
          <Typography>{DEVELOPMENT_EXPERTISE_LEFT_PARAGRAPH}</Typography>
          <Typography>{DEVELOPMENT_EXPERTISE_RIGHT_PARAGRAPH}</Typography>
          <Button endIcon={expanded ? <KeyboardArrowUp/> : <KeyboardArrowDown/>}
                  onClick={() => setExpanded(!expanded)}>
            Show {expanded ? 'less' : 'more'}
          </Button>
        </div>

        <div className={styles['expertise-block__cards']}>
          {DEVELOPMENT_EXPERTISE_CARDS.map(({ icon, title }) => (
              <React.Fragment key={title}>
                <img
                    src={icons.nodes.find(({ name }) => name === icon)?.publicURL}
                    alt={title}
                />
                <div className={styles['expertise-block__cards-mobile']}>
                  <img
                      src={mobileIcons.nodes.find(({ name }) => name === icon)?.publicURL}
                      key={title}
                      alt={title}
                  />
                  <Typography align={"center"}>{title}</Typography>
                </div>
              </React.Fragment>

          ))}
        </div>
        </div>
      </div>

      <div className={styles['development-services-block']}>
        <div className={styles['development-services-container']}>
          <div className={styles['development-services-text']}>
            <Typography className={styles['development-services-heading']} color="secondary" variant="h2">
              {DEVELOPMENT_SERVICES_HEADING}
            </Typography>
            <p className={styles['development-services-paragraph']}>{DEVELOPMENT_SERVICES_TEXT}</p>
          </div>
          <div className={styles['development-services-block__cards']}>
            {DEVELOPMENT_SERVICES_CARDS.map(({ icon, title }) => (
              <HorizontalCard
                icon={icons.nodes.find(({ name }) => name === icon).publicURL}
                title={title.map(line => (
                  <React.Fragment key={line}>
                    {line}
                    <br />
                  </React.Fragment>
                ))}
                key={title}
              />
            ))}
          </div>
        </div>
      </div>

      <div className={styles['team-block']}>
        <Typography variant={'h2'} className={styles['team-block-heading']} align="center" color="secondary">
          {DEVELOPMENT_DEDICATED_TEAM_HEADING}
        </Typography>
        {DEVELOPMENT_DEDICATED_TEAM_TEXT.map((item, index) => (
          <p className={styles['team-block-paragraph']} key={`team-block-paragraph-${index}`}>
            {item}
          </p>
        ))}
        <div className={styles['team-block-cards']}>
          {DEVELOPMENT_DEDICATED_TEAM_CARDS.map(({icon, title}) => <HorizontalCard
            icon={icons.nodes.find(({name}) => name === icon).publicURL}
            key={icon}
            title={title}
          />)}
        </div>
      </div>

      <div className={styles['qa-block-container']}>
        <div className={styles['qa-block']}>
          <Typography variant={'h2'} className={styles['qa-block-heading']} align="center" color="secondary">
            {DEVELOPMENT_QA_HEADING}
          </Typography>
          <p className={styles['qa-block-paragraph']}>
            {DEVELOPMENT_QA_TEXT}
          </p>
          <div className={styles['image-container']}>
            <a href={qa.nodes[0].publicURL} target="_blank">
              <Img fluid={qa.nodes[0].childImageSharp.fluid} alt="qa illustration" />
            </a>
          </div>
        </div>
      </div>

      <div className={styles['agile-block']}>
        <Typography variant={'h2'} className={styles['agile-block__heading']} align="center" color="secondary">
          {DEVELOPMENT_AGILE_HEADING}
        </Typography>

        <div className={styles['agile-block__text']}>
          <Typography>{DEVELOPMENT_AGILE_TEXT}</Typography>
        </div>
        <div className={styles['image-container']}>
          <a href={agile.nodes[0].publicURL} target="_blank">
            <Img fluid={agile.nodes[0].childImageSharp.fluid} alt="agile illustration" />
          </a>
        </div>
      </div>

      <div className={styles['advantages-block']}>
        <Typography variant={'h2'} className={styles['agile-block__heading']} align="center" color="secondary">
          {DEVELOPMENT_ADVANTAGES_HEADING}
        </Typography>

        {DEVELOPMENT_ADVANTAGES_CARDS.map(card => (
          <div className={styles['advantages-block__card']} key={card.icon}>
            <div className={styles['advantages-block__img-container']}>
              <img src={advantagesMap[card.icon]} alt={card.icon}/>
            </div>
            <div>
              <Typography variant={'h3'}>{card.title}</Typography>
              <Typography>{card.text}</Typography>
            </div>
          </div>
        ))}
      </div>
      <div className={styles['insights-block']}>
        <Typography align="center" className={styles['insights-block-heading']} color="secondary" variant="h2">
          {DEVELOPMENT_INSIGHTS_HEADING}
        </Typography>
        <Feed edges={postsEdges} />
      </div>
      <LeaveMessageBanner />
    </div>
  );
};

export default CustomSoftwareDevelopmentPage;
